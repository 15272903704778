declare var config: any;

export const environment = {
  production: false,
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  svgOrigPath: './assets/media/svg/icons/',
  tokenApiMoodle: atob(config.token),
  appVersion: 'v1.3.15',
  strRegxpag: 'Registros por página',
  apiUrl: `${atob(config.api)}/api/`,
  downloadUrl: `${atob(config.api)}/`,
  campusUrl: `${atob(config.campus)}/`,
  urlApiMoodle: `${atob(config.campus)}/webservice/rest/server.php`,
  urlCampusTypeAuth: `${atob(config.campus)}/webservice/rest/server.php?wstoken=${atob(config.token)}&moodlewsrestformat=json&wsfunction=webservice_bcn_get_list_auth`,
  urlCampusLang: `${atob(config.campus)}/webservice/rest/server.php?wstoken=${atob(config.token)}&moodlewsrestformat=json&wsfunction=webservice_bcn_get_list_language`,
  apiUrlTomaConocimiento: `${atob(config.campus)}/webservice/rest/server.php?wstoken=${atob(config.token)}&moodlewsrestformat=json&wsfunction=webservice_bcn_get_sworn_declaration`,
  apiUrlEncuesta: `${atob(config.campus)}/webservice/rest/server.php?wstoken=${atob(config.token)}&wsfunction=webservice_bcn_get_courses_satisfaction_surveys&moodlewsrestformat=json`,
  apiUrlEncuestaCurso: `${atob(config.campus)}/webservice/rest/server.php?wstoken=${atob(config.token)}&wsfunction=webservice_bcn_get_courses_satisfaction_surveys_by_course&moodlewsrestformat=json`,
};